@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  display: flex;
  flex-direction: column;
  width: 100%;
}

body{
  background-color: rgb(10, 72, 110);
  height: 100%;
}

header {
  color: rgb(201, 224, 238);
}

a {
  text-decoration: none;
}

.header {
  z-index: 1;
  height: 6rem;
  top: 0;
  left: 0;
  padding: 0.5em 1.5em 0.5em 1.5em;
  background-image: linear-gradient(#37877b 10px,  #336280);
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.5);
  border-bottom: 1px solid rgb(46, 90, 117);
  align-items: center;
  justify-content: space-between;
}

.header-name {
  font-family: 'Raleway';
  margin: 1rem auto;
  width: 100%;
  font-weight: bold;
  line-height: 0.8;
  text-align: center;
  font-size: 38px;
}

.description {
  width: 100%;
}

.subdescription {
  font-family: 'Raleway';
  font-size: 15px;
  margin: 0.5rem auto;
  text-align: center;
  word-wrap: break-word;
  padding: 0 1.5rem;
}

.logo-adeq {
  display: block;
  height: 100%;
  border-radius: 50%;
}

.pp {
  display: block;
  height: 90%;
  border-radius: 50%;
}

.content {
  align-items: center;
  height: calc(100vh - 12.05rem);
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-direction: row;
}

.content-disposition {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-content {
  width: 30%;
  box-sizing: border-box;
}

.labels {
  display: block;
  margin: 1em 0;
  font-family: 'Raleway';
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  color: white;
}

.footer-content {
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  padding: 0.5em 0.5em 0 0.5em;
  bottom: 0;
  height: 5rem;
  width: 100%;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  align-content: center;
}

.footer-text-p {
  display: block;
  margin: 0;
  font-family: 'Raleway';
  font-weight: bold;
  color: white;
  font-size: 13px;
}

.footer-lefter {
  width: 70%;
  display: flex;
  align-items: center;
  text-align: left;
}

.footer-righter {
  width: 70%;
  text-align: right;
}

.App-link {
  color: #61dafb;
}

.column {
  width: 8.33%;
  float: left;
}

.flex {
  display: flex;
}

.footer-righter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media only screen and (min-width: 480px) and (max-width: 900px) {
  .content {
    flex-direction: column;
  }

  .content-disposition {
    flex-direction: row;
    justify-content: space-evenly;
    width: 50vw;
  }

  .image-content {
    width: 20%;
  }
}

@media only screen and (max-width: 480px) {
  .header {
    height: 5rem;
  }

  .header-name {
    font-size: 24px;
    margin: 0.7rem auto;
  }

  .subdescription {
    font-size: 12px;
  }

  .content {
    flex-direction: column;
    height: calc(100vh - 10.05rem);
  }

  .content-disposition {
    flex-direction: row;
    justify-content: space-evenly;
    width: 90vw;
  }

  .image-content {
    width: 19%;
  }

  .labels {
    font-size: 28px;
  }

  .footer-image {
    width: 2.5rem;
  }

  .footer-content {
    height: 4rem;
  }
}
